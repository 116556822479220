import React from "react";
import { Link } from "gatsby";

// Components
import Button from "../custom-widgets/button";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import styles from "./mini-band-custom.module.scss";

const MiniBandCustom = (props) => {
  return (
    <section id="mini-band-custom-section" className={props.sectionClass}>
      <div className="container">
        <div className={`row ${props.rowColsClass}`}>
          {props.cards.map((card) => {
            let btnClass = card.button.class + " " + styles.miniBandBtn;
            card.button.class = btnClass;
            return (
              <div key={card.id} className="col mb-4">
                <div className={`card h-100 ${card.class} ${styles.miniBandItem}`}>
                  <div className="card-body d-flex flex-column align-items-center justify-content-center">
                    {card.showIcon && card.icon && (
                      <div className={styles.miniBandIconWrapper}>
                        <FontAwesomeIcon
                          className={`${card.icon.class} ${styles.miniBandIcon}`}
                          icon={[`${card.icon.lib}`, `${card.icon.name}`]}
                        />
                      </div>
                    )}
                    {card.title && <h4 className={`card-title ${card.titleClass}`}>{card.title}</h4>}
                    {card.text && (
                      <p className={`card-text ${card.textClass}`} dangerouslySetInnerHTML={{ __html: card.text }} />
                    )}
                    <div className="mt-auto text-center">
                      {card.anchor && card.anchor.text && <Link to={card.anchor.url}>{card.anchor.text}</Link>}
                      {card.button && card.button.text && <Button {...card.button} />}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default MiniBandCustom;

MiniBandCustom.defaultProps = {
  sectionClass: "bg-light",
  rowColsClass: "row-cols-1 row-cols-md-2 row-cols-lg-3",
  cards: [
    {
      id: 1,
      class: "bg-light",
      colClass: "col mb-4",
      showIcon: true,
      icon: {
        lib: "fal",
        name: "money-bill-wave",
        class: "fa-3x"
      },
      titleClass: "text-center",
      title: "Open an Account Online. ",
      textClass: "text-center",
      text: "Open a personal account online.",
      button: {
        id: "personal-account-cta-button",
        showIcon: false,
        class: "btn-primary stretched-link",
        text: "Open a Personal Account",
        url: "/open-bank-account-online"
      }
    },
    {
      id: 2,
      class: "bg-light",
      colClass: "col mb-4",
      showIcon: true,
      icon: {
        lib: "fal",
        name: "briefcase",
        class: "fa-3x"
      },
      titleClass: "text-center",
      title: "Open a Business Account Online.",
      textClass: "text-center",
      text: "Open a Small Business Account Online",
      button: {
        id: "business-account-cta-button",
        class: "btn-primary stretched-link",
        showIcon: false,
        text: "Open a Business Account",
        url: "/business-banking/open-business-bank-account-online"
      }
    },
    {
      id: 3,
      class: "bg-light",
      colClass: "col mb-4",
      showIcon: true,
      icon: {
        lib: "fal",
        name: "tv",
        class: "fa-3x"
      },
      titleClass: "text-center",
      title: "Already have an account? ",
      textClass: "text-center",
      text: "Sign up for online access.",
      button: {
        id: "online-banking-cta-button",
        type: "anchor",
        externalLink: true,
        showIcon: false,
        class: "btn-primary stretched-link olb-sign-in-link",
        text: "Enroll in Online Banking",
        url: "https://online.wafdbank.com/link/register?application=OLB",
        target: "blank"
      }
    },
    {
      id: 4,
      class: "bg-light",
      colClass: "col mb-4",
      showIcon: true,
      icon: {
        lib: "fal",
        name: "home",
        class: "fa-3x"
      },
      titleClass: "text-center",
      title: "Buying or Refinancing a Home? ",
      textClass: "text-center",
      text: "Get in touch with your local branch banker!",
      button: {
        id: "mortgage-cta-button",
        type: "link",
        showIcon: false,
        class: "btn-primary stretched-link no-max-width",
        text: "Contact a Loan Officer",
        url: "/personal-banking/contact-loan-officer"
      }
    }
  ]
};
